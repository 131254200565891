import React, { useState, useEffect } from "react";
import cx from "classnames";
// import { BookingModal } from "../booking-modal";
import { openPopupWidget } from "react-calendly";

import { ReactComponent as HushBookingSVG } from "src/images/svg/hush-booking.svg";
import { ReactComponent as HushBookingMobileSVG } from "src/images/svg/hush-booking-mobile.svg";
import { ReactComponent as HushBookClosed } from "src/images/svg/hush-book-closed.svg";
import { ReactComponent as HushBookOpen } from "src/images/svg/hush-book-open.svg";
import { ReactComponent as HushBookNowSVG } from "src/images/svg/hush-book-now.svg";
import { ReactComponent as HushBookNowMobileSVG } from "src/images/svg/hush-book-now-mobile.svg";

import styles from "./booking.module.css";

interface HushBookingProps {
	data: {
		bookingCopy: string;
		bookNowCopy: string;
	};
}
export const HushBooking = ({ data }: HushBookingProps) => {
	const { bookingCopy, bookNowCopy } = data;

	const onClick = () =>
		openPopupWidget({
			url: "https://calendly.com/firstchild",
			pageSettings: {
				backgroundColor: "e2ded4",
				hideEventTypeDetails: false,
				hideLandingPageDetails: false,
				primaryColor: "000000",
				textColor: "000000",
			},
		});

	return (
		<>
			<div className={styles.booking} data-custom-cursor="quote">
				<div className={styles.leftColumn}>
					<HushBookingMobileSVG
						className={cx("hide-min-medium", styles.bookingMobileSVG)}
					/>
					<HushBookingSVG className={cx("hide-small", styles.bookingSVG)} />
					<p className={cx("kessel lower", styles.bookingCopy)}>
						{bookingCopy}
					</p>
				</div>
				<div className={styles.rightColumn}>
					<div className={styles.stack}>
						<p className={cx("kessel lower hide-small", styles.bookNowCopy)}>
							{bookNowCopy}
						</p>
						<button
							onClick={onClick}
							className={cx("reset", styles.bookNowButton)}
						>
							<HushBookNowMobileSVG
								className={cx("hide-min-medium", styles.bookNowMobileSVG)}
							/>
							<div className={styles.book}>
								<HushBookClosed className={styles.bookClosed} />
								<HushBookOpen className={styles.bookOpen} />
							</div>
							<HushBookNowSVG className={cx("hide-small", styles.bookNowSVG)} />
						</button>
					</div>
				</div>
			</div>
		</>
	);
};
