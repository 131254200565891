import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useInView } from "react-intersection-observer";

import cx from "classnames";

import Img from "gatsby-image/withIEPolyfill";
import { PageLink } from "src/components/link";
import { HushEvents as HushEventsAnimation } from "src/components/animations/hush-events";

import styles from "./events.module.css";

interface HushEventsProps {
	data: {
		copy: string;
	};
}
export const HushEvents = ({ data }: HushEventsProps) => {
	const [ref, inView] = useInView({
		threshold: 1,
		triggerOnce: true,
	});

	const { copy } = data;

	const hushBook = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "hush-book-2.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<div className={styles.events} data-custom-cursor="star">
			<div className={styles.inner}>
				<div className={styles.leftColumn}>
					<div className={styles.leftColumnInner} ref={ref}>
						{/* <PageLink className={styles.eventsLink} to="/events">
							<HushEventsAnimation
								className={cx(styles.hushEventsSVG, inView ? "animate" : "")}
							/>
						</PageLink> */}
						<HushEventsAnimation
							className={cx(styles.hushEventsSVG, inView ? "animate" : "")}
						/>
						<p className={cx("times", styles.copy)}>{copy}</p>
					</div>
				</div>
				<div className={cx("hide-small", styles.rightColumn)}>
					<Img
						className={styles.bookImage}
						fluid={hushBook.file.childImageSharp.fluid}
					/>
				</div>
			</div>
		</div>
	);
};
