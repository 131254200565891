import React, { useEffect } from "react";

import { useSetPage } from "src/context/siteContext";
import { SEO } from "src/components/SEO";
import { RevealOnMount } from "src/components/reveal-on-mount";

import { HushIntro } from "src/components/hush/intro";
import { HushFooter } from "src/components/hush/footer";

import { HushShop } from "src/components/hush/shop";
import { HushEvents } from "src/components/hush/events";
import { HushBooking } from "src/components/hush/booking";
import { HushGallery } from "src/components/hush/gallery";

const Modules = ({ type, data }: { type: string; data: any }) => {
	if (data.hide) {
		// if module is hidden return empty fragment
		return <></>;
	}
	switch (type) {
		case "hushShopModule":
			return <HushShop data={data} />;
		case "hushEventsModule":
			return <HushEvents data={data} />;
		case "hushBookingModule":
			return <HushBooking data={data} />;
		case "hushGalleryModule":
			return <HushGallery data={data} />;
		default:
			return <h2>{type}</h2>;
	}
};

const RenderModules = (modules: []) => {
	if (modules) {
		return modules.map((module: { _key: string; _type: string }) => {
			return <Modules key={module._key} type={module._type} data={module} />;
		});
	}
};

export interface HushProps {
	pageContext: {
		main: {
			slug: {
				current: string;
			};
			title: string;
			introCopy: string;
			modules: [];
			footer: {
				building: any;
				buildingLogo: {
					asset: {
						url: string;
					};
				};
			};
		};
		meta: {};
	};
	location: any;
}

const Hush = ({ location, pageContext }: HushProps) => {
	const setPage = useSetPage();
	const { main, meta } = pageContext;
	const { slug, title } = main;

	useEffect(() => {
		setPage(slug.current);
	}, [0]);

	const path = `${slug.current}`;
	return (
		<RevealOnMount location={location}>
			<SEO metaInfo={meta} pagePath={path} />
			<div className={slug.current}>
				<HushIntro introCopy={main.introCopy} />
				{RenderModules(main.modules)}
				<HushFooter data={main.footer} />
			</div>
		</RevealOnMount>
	);
};

export default Hush;
