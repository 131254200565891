import React from "react";
import cx from "classnames";
import { useInView } from "react-intersection-observer";
import { PageLink } from "src/components/link";
import Image from "src/components/image";
import { getInternalLinkPath } from "src/utils/getInternalLinkPath";
import { HushBorder } from "src/components/animations/hush-border";
import { HushBowl } from "src/components/animations/hush-bowl";
import { HushBorderMobile } from "src/components/animations/hush-border-mobile";

import styles from "./footer.module.css";

interface HushFooterProps {
	data: {
		building: any;
		buildingLogo: {
			asset: {
				url: string;
			};
		};
	};
}

export const HushFooter = ({ data }: HushFooterProps) => {
	const [ref, inView] = useInView({
		threshold: 0.5,
		triggerOnce: true,
	});

	const { building, buildingLogo } = data;
	return (
		<div className={styles.footer} ref={ref} data-custom-cursor="quote">
			<div className={styles.inner}>
				<div className={cx("hide-small", styles.leftColumn)}>
					<HushBorder
						className={cx(styles.hushBorderLogo, inView ? "animate" : "")}
						animate={inView}
					/>
					<PageLink to={getInternalLinkPath(building)}>
						<Image
							src={buildingLogo.asset.url}
							className={cx(styles.buildingLogo, inView ? styles.animate : "")}
						/>
					</PageLink>
				</div>
				<HushBowl
					className={cx("hide-small", styles.hushBowl, inView ? "animate" : "")}
				/>
				<HushBorderMobile
					className={cx(
						"hide-min-medium",
						styles.hushBorderMobile,
						inView ? "animate" : ""
					)}
				/>
			</div>
		</div>
	);
};
