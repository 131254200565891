import React from "react";
import cx from "classnames";
import { useInView } from "react-intersection-observer";
import { useEmblaCarousel } from "embla-carousel/react";
import Image from "src/components/image";

import { HushStretch } from "src/components/animations/hush-stretch";

import styles from "./gallery.module.css";

interface HushGalleryProps {
	data: {
		images: [
			{
				_key: string;
				image: {
					asset: {
						_id: string;
					};
				};
				imageMobile?: {
					asset: {
						_id: string;
					};
				};
			}
		];
	};
}
export const HushGallery = ({ data }: HushGalleryProps) => {
	const { images } = data;

	const [ref, inView] = useInView({
		threshold: 0.5,
		triggerOnce: true,
	});

	const [viewportRef, embla] = useEmblaCarousel({ loop: true });

	return (
		<div className={styles.gallery} ref={ref} data-custom-cursor="star">
			<div className={styles.inner}>
				<HushStretch className={styles.hushStretch} animate={inView} />

				<div className={styles.slideshowContainer}>
					<div className="embla x y">
						<div className="embla__viewport x y" ref={viewportRef}>
							<div className={"embla__container x y"}>
								{images.map((slide, i) => {
									return (
										<div className={styles.slideshowSlide} key={slide._key}>
											<Image
												imageId={slide.image.asset._id}
												objectFit="cover"
												className={styles.slideshowImage}
												style={{
													width: "100%",
													height: "100%",
													position: "absolute",
													padding: 0,
												}}
												imgStyle={{ height: "100%" }}
											/>
											{slide.imageMobile && slide.imageMobile.asset && (
												<Image
													imageId={slide.imageMobile.asset._id}
													objectFit="cover"
													className={styles.slideshowImageMobile}
													style={{
														width: "100%",
														height: "100%",
														position: "absolute",
														padding: 0,
													}}
													imgStyle={{ height: "100%" }}
												/>
											)}
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
