import React from "react";
import { graphql } from "gatsby";

import GraphQLErrorList from "../components/graphql-error-list";
import Hush from "../templates/hush";

export const query = graphql`
	query HushPageQuery {
		page: sanityHush(_id: { regex: "/(drafts.|)001hush/" }) {
			content: _rawContent(resolveReferences: { maxDepth: 10 })
		}
	}
`;

const HushPage = (props) => {
	const { data, errors } = props;

	if (errors) {
		return <GraphQLErrorList errors={errors} />;
	}

	return (
		<>
			<Hush location={props.location} pageContext={data.page.content} />
		</>
	);
};

export default HushPage;
