import React from "react";
import { motion } from "framer-motion";

export const HushSVG = ({ className }: { className?: string }) => {
	return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xlinkHref="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 475 470"
			xmlSpace="preserve"
			className={className}
		>
			<g id="H_left">
				<motion.path
					animate={{
						d: `M129.57,0.36H82.96c-2.01,0-3.35,1.34-3.35,3.34v202.97h-0.38H73.3h-0.38V3.7c0-2-1.34-3.34-3.35-3.34H2.96
		C1.18,0.36,0,1.7,0,3.7v462.96c0,2,1.18,3.34,2.96,3.34h66.61c2.01,0,3.35-1.34,3.35-3.34V256.79h0.38h5.92h0.38v209.87
		c0,2,1.34,3.34,3.35,3.34h46.61c1.78,0,2.96-1.34,2.96-3.34V3.7C132.53,1.7,131.35,0.36,129.57,0.36z`,
					}}
					transition={{ ease: "easeInOut", yoyo: Infinity, duration: 2.5 }}
					d="M89.57,0.36H42.96c-2.01,0-3.35,1.34-3.35,3.34v202.97h-0.38H33.3h-0.38V3.7c0-2-1.34-3.34-3.35-3.34H2.96
		C1.18,0.36,0,1.7,0,3.7v462.96c0,2,1.18,3.34,2.96,3.34h26.61c2.01,0,3.35-1.34,3.35-3.34V256.79h0.38h5.92h0.38v209.87
		c0,2,1.34,3.34,3.35,3.34h46.61c1.78,0,2.96-1.34,2.96-3.34V3.7C92.53,1.7,91.35,0.36,89.57,0.36z"
				/>
			</g>
			<g id="U">
				<motion.path
					animate={{
						d: `M176.9,470h42.74c23.75-6.37,34.9-26.07,34.9-68.4V3.34c0-2-1.18-3.34-2.96-3.34h-46.9c-1.92,0-3.2,1.28-3.2,3.2v395.94
		c0,1.06,0,1.06,0,2.98v14.85c0,2.67-0.24,5.35-3.2,5.35c-2.96,0-3.18-2.9-3.18-5.57l-0.02-14.63c0-1.92,0-1.92,0-2.98V3.2
		c0-1.92-1.28-3.2-3.2-3.2h-46.9c-1.78,0-2.96,1.34-2.96,3.34V401.6C142.01,443.93,153.16,463.63,176.9,470z`,
					}}
					transition={{ ease: "easeInOut", yoyo: Infinity, duration: 2.5 }}
					d="M136.9,470h42.74c23.75-6.37,34.9-26.07,34.9-68.4V3.34c0-2-1.18-3.34-2.96-3.34h-46.9c-1.92,0-3.2,1.28-3.2,3.2v395.94
		c0,1.06,0,1.06,0,2.98v14.85c0,2.67-0.24,5.35-3.2,5.35c-2.96,0-3.18-2.9-3.18-5.57l-0.02-14.63c0-1.92,0-1.92,0-2.98V3.2
		c0-1.92-1.28-3.2-3.2-3.2h-46.9c-1.78,0-2.96,1.34-2.96,3.34V401.6C102.01,443.93,113.16,463.63,136.9,470z"
				/>
			</g>
			<g id="S">
				<motion.path
					animate={{
						d: `M297.45,470h42.1c20.11-5.45,35.22-21.46,35.22-61.72V278.62c0-35.42-4.74-46.12-34.35-65.5
		c-0.29-0.19-0.57-0.36-0.86-0.54c-12.51-6.92-24.25-12.44-24.25-28.58V61.18c0-2.56,0-5.12,3.2-5.12c3.2,0,3.2,2.56,3.2,5.12
		v133.06c0,1.82,1.18,3.03,2.94,3.15h47.16c1.78,0,2.96-1.34,2.96-3.34V62.08c0-41.18-15.81-56.99-36.61-62.08h-39.31
		c-20.8,5.09-36.61,20.89-36.61,62.08v133c0,35.17,6.45,43.34,34.95,61.77v-0.05c13,6.2,24.52,12.21,24.52,32.74v119.62
		c0,2.56,0,5.12-3.2,5.12c-3.2,0-3.2-2.56-3.2-5.12V274.83c0-1.42-0.71-2.49-1.86-2.95c-0.19-0.06-0.39-0.1-0.6-0.12
		c-0.1-0.02-0.2-0.04-0.3-0.05H265.2c-1.78,0-2.96,1.34-2.96,3.34v133.23C262.24,448.54,277.35,464.55,297.45,470z`,
					}}
					transition={{ ease: "easeInOut", yoyo: Infinity, duration: 2.5 }}
					d="M257.45,470h42.1c20.11-5.45,35.22-21.46,35.22-61.72V278.62c0-35.42-4.74-46.12-34.35-65.5
		c-0.29-0.19-0.57-0.36-0.86-0.54c-12.51-6.92-24.25-12.44-24.25-28.58V61.18c0-2.56,0-5.12,3.2-5.12c3.2,0,3.2,2.56,3.2,5.12
		v133.06c0,1.82,1.18,3.03,2.94,3.15h47.16c1.78,0,2.96-1.34,2.96-3.34V62.08c0-41.18-15.81-56.99-36.61-62.08h-39.31
		c-20.8,5.09-36.61,20.89-36.61,62.08v133c0,35.17,6.45,43.34,34.95,61.77v-0.05c13,6.2,24.52,12.21,24.52,32.74v119.62
		c0,2.56,0,5.12-3.2,5.12c-3.2,0-3.2-2.56-3.2-5.12V274.83c0-1.42-0.71-2.49-1.86-2.95c-0.19-0.06-0.39-0.1-0.6-0.12
		c-0.1-0.02-0.2-0.04-0.3-0.05H225.2c-1.78,0-2.96,1.34-2.96,3.34v133.23C222.24,448.54,237.35,464.55,257.45,470z"
				/>
			</g>
			<g id="H_right">
				<motion.path
					animate={{
						d: `M472.04,0.36h-26.61c-2.01,0-3.35,1.34-3.35,3.34v202.97h-0.38h-5.92h-0.38V3.7c0-2-1.34-3.34-3.35-3.34h-46.61
		c-1.78,0-2.96,1.34-2.96,3.34v462.96c0,2,1.18,3.34,2.96,3.34h46.61c2.01,0,3.35-1.34,3.35-3.34V256.79h0.38h5.92h0.38v209.87
		c0,2,1.34,3.34,3.35,3.34h26.61c1.78,0,2.96-1.34,2.96-3.34V3.7C475,1.7,473.82,0.36,472.04,0.36z`,
					}}
					transition={{ ease: "easeInOut", yoyo: Infinity, duration: 2.5 }}
					d="M472.04,0.36h-46.61c-2.01,0-3.35,1.34-3.35,3.34v202.97h-0.38h-5.92h-0.38V3.7c0-2-1.34-3.34-3.35-3.34h-66.61
		c-1.78,0-2.96,1.34-2.96,3.34v462.96c0,2,1.18,3.34,2.96,3.34h66.61c2.01,0,3.35-1.34,3.35-3.34V256.79h0.38h5.92h0.38v209.87
		c0,2,1.34,3.34,3.35,3.34h46.61c1.78,0,2.96-1.34,2.96-3.34V3.7C475,1.7,473.82,0.36,472.04,0.36z"
				/>
			</g>
		</svg>
	);
};
