import React from "react";
import { motion } from "framer-motion";

export const HushStretch = ({
	className,
	animate,
}: {
	className?: string;
	animate: boolean;
}) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			xlinkHref="http://www.w3.org/1999/xlink"
			viewBox="0 0 181 567"
			shapeRendering="geometricPrecision"
			textRendering="geometricPrecision"
		>
			<motion.path
				id="H_first"
				variants={{
					stretch: {
						d: `M41.800000,1.300000L24,1.300000C23.200000,1.300000,22.700000,1.800000,22.700000,2.600000L21.712014,249.573498L21.612014,249.573498L19.312014,249.573498L19.212014,249.573498L20.200000,2.500000C20.200000,1.700000,19.700000,1.200000,18.900000,1.200000L1.100000,1.200000C0.500000,1.300000,0,1.800000,0,2.500000L0.183039,563.660777C0.183039,564.460777,0.683039,564.960777,1.283039,564.960777L19.083039,564.960777C19.883039,564.960777,20.383039,564.460777,20.383039,563.660777L19.212014,268.473498L19.312014,268.473498L21.612014,268.473498L21.712014,268.473498L22.883039,563.660777C22.883039,564.460777,23.383039,564.960777,24.183039,564.960777L41.983039,564.960777C42.683039,564.960777,43.083039,564.460777,43.083039,563.660777L42.900000,2.500000C42.900000,1.800000,42.400000,1.300000,41.800000,1.300000Z`,
					},
				}}
				animate={animate ? "stretch" : "initial"}
				transition={{ ease: [0.32, 0, 0.67, 0], duration: 1.2 }}
				d="M41.800000,1.300000L24,1.300000C23.200000,1.300000,22.700000,1.800000,22.700000,2.600000L22.700000,75.300000L22.600000,75.300000L20.300000,75.300000L20.200000,75.300000L20.200000,2.500000C20.200000,1.700000,19.700000,1.200000,18.900000,1.200000L1.100000,1.200000C0.500000,1.300000,0,1.800000,0,2.500000L0,174C0,174.800000,0.500000,175.300000,1.100000,175.300000L18.900000,175.300000C19.700000,175.300000,20.200000,174.800000,20.200000,174L20.200000,94.200000L20.300000,94.200000L22.600000,94.200000L22.700000,94.200000L22.700000,174C22.700000,174.800000,23.200000,175.300000,24,175.300000L41.800000,175.300000C42.500000,175.300000,42.900000,174.800000,42.900000,174L42.900000,2.500000C42.900000,1.800000,42.400000,1.300000,41.800000,1.300000Z"
			/>
			<motion.path
				id="U"
				variants={{
					stretch: {
						d: `M87.800000,1.300000L69.900000,1.300000C69.200000,1.300000,68.700000,1.800000,68.700000,2.500000L68.296466,538.618375C68.296466,539.018375,68.296466,539.418375,68.196466,539.718375L68.196466,545.318375C68.196466,546.318375,68.196466,547.318375,67.096466,547.318375C65.996466,547.318375,65.996466,546.318375,65.996466,545.318375L65.996466,539.718375C65.896466,539.418375,65.896466,539.018375,65.896466,538.618375L66.300000,2.500000C66.300000,1.800000,65.800000,1.300000,65.100000,1.300000L47.200000,1.300000C46.500000,1.300000,46.100000,1.800000,46.100000,2.600000L45.696466,539.618375C45.696466,559.918375,52.496466,566.518375,67.096466,566.518375C81.796466,566.518375,88.496466,559.918375,88.496466,539.618375L88.900000,2.500000C88.900000,1.800000,88.500000,1.300000,87.800000,1.300000Z`,
					},
				}}
				animate={animate ? "stretch" : "initial"}
				transition={{ ease: [0.32, 0, 0.67, 0], delay: 0.03, duration: 1.2 }}
				d="M87.800000,1.300000L69.900000,1.300000C69.200000,1.300000,68.700000,1.800000,68.700000,2.500000L68.700000,148.700000C68.700000,149.100000,68.700000,149.500000,68.600000,149.800000L68.600000,155.400000C68.600000,156.400000,68.600000,157.400000,67.500000,157.400000C66.400000,157.400000,66.400000,156.400000,66.400000,155.400000L66.400000,149.800000C66.300000,149.500000,66.300000,149.100000,66.300000,148.700000L66.300000,2.500000C66.300000,1.800000,65.800000,1.300000,65.100000,1.300000L47.200000,1.300000C46.500000,1.300000,46.100000,1.800000,46.100000,2.600000L46.100000,149.700000C46.100000,170,52.900000,176.600000,67.500000,176.600000C82.200000,176.600000,88.900000,170,88.900000,149.700000L88.900000,2.500000C88.900000,1.800000,88.500000,1.300000,87.800000,1.300000Z"
			/>
			<motion.path
				id="S"
				variants={{
					stretch: {
						d: `M113.500000,0C102.200000,0,92.100000,4.100000,92.100000,24.400000L91.973913,246.769565C91.973913,260.169565,94.473913,263.269565,105.273913,270.269565L105.273913,270.269565C110.273913,272.669565,114.573913,274.869565,114.573913,282.669565L114.277739,543.157597C114.277739,544.157597,114.277739,545.057597,113.077739,545.057597C111.877739,545.057597,111.877739,544.057597,111.877739,543.157597L112.173913,277.069565C112.173913,276.569565,111.873913,276.169565,111.473913,275.969565C111.373913,275.969565,111.373913,275.969565,111.273913,275.969565C111.273913,275.969565,111.173913,275.969565,111.173913,275.969565L93.173913,275.969565C92.473913,275.969565,92.073913,276.469565,92.073913,277.269565L91.777739,542.957597C91.777739,563.257597,101.977739,567.357597,113.177739,567.357597C124.477739,567.357597,134.577739,563.257597,134.577739,542.957597L134.873913,278.569565C134.873913,265.069565,133.073913,261.069565,121.773913,253.669565C121.673913,253.569565,121.573913,253.569565,121.473913,253.469565C116.673913,250.869565,112.273913,248.769565,112.273913,242.569565L112.400000,24C112.400000,23,112.400000,22.100000,113.600000,22.100000C114.800000,22.100000,114.800000,23.100000,114.800000,24L114.673913,246.369565C114.673913,247.069565,115.073913,247.569565,115.773913,247.569565L133.773913,247.569565C134.473913,247.569565,134.873913,247.069565,134.873913,246.269565L135,24.400000C135,4.100000,124.800000,0,113.500000,0Z`,
					},
				}}
				animate={animate ? "stretch" : "initial"}
				transition={{ ease: [0.32, 0, 0.67, 0], delay: 0.06, duration: 1.2 }}
				d="M113.500000,0C102.200000,0,92.100000,4.100000,92.100000,24.400000L92.100000,71.200000C92.100000,84.600000,94.600000,87.700000,105.400000,94.700000L105.400000,94.700000C110.400000,97.100000,114.700000,99.300000,114.700000,107.100000L114.700000,152.500000C114.700000,153.500000,114.700000,154.400000,113.500000,154.400000C112.300000,154.400000,112.300000,153.400000,112.300000,152.500000L112.300000,101.500000C112.300000,101,112,100.600000,111.600000,100.400000C111.500000,100.400000,111.500000,100.400000,111.400000,100.400000C111.400000,100.400000,111.300000,100.400000,111.300000,100.400000L93.300000,100.400000C92.600000,100.400000,92.200000,100.900000,92.200000,101.700000L92.200000,152.300000C92.200000,172.600000,102.400000,176.700000,113.600000,176.700000C124.900000,176.700000,135,172.600000,135,152.300000L135,103C135,89.500000,133.200000,85.500000,121.900000,78.100000C121.800000,78,121.700000,78,121.600000,77.900000C116.800000,75.300000,112.400000,73.200000,112.400000,67L112.400000,24C112.400000,23,112.400000,22.100000,113.600000,22.100000C114.800000,22.100000,114.800000,23.100000,114.800000,24L114.800000,70.800000C114.800000,71.500000,115.200000,72,115.900000,72L133.900000,72C134.600000,72,135,71.500000,135,70.700000L135,24.400000C135,4.100000,124.800000,0,113.500000,0Z"
			/>
			<motion.path
				id="H_last"
				variants={{
					stretch: {
						d: `M41.800000,1.300000L24,1.300000C23.200000,1.300000,22.700000,1.800000,22.700000,2.600000L21.712014,249.573498L21.612014,249.573498L19.312014,249.573498L19.212014,249.573498L20.200000,2.500000C20.200000,1.700000,19.700000,1.200000,18.900000,1.200000L1.100000,1.200000C0.500000,1.300000,0,1.800000,0,2.500000L0.183039,563.660777C0.183039,564.460777,0.683039,564.960777,1.283039,564.960777L19.083039,564.960777C19.883039,564.960777,20.383039,564.460777,20.383039,563.660777L19.212014,268.473498L19.312014,268.473498L21.612014,268.473498L21.712014,268.473498L22.883039,563.660777C22.883039,564.460777,23.383039,564.960777,24.183039,564.960777L41.983039,564.960777C42.683039,564.960777,43.083039,564.460777,43.083039,563.660777L42.900000,2.500000C42.900000,1.800000,42.400000,1.300000,41.800000,1.300000Z`,
					},
				}}
				animate={animate ? "stretch" : "initial"}
				transition={{ ease: [0.32, 0, 0.67, 0], delay: 0.09, duration: 1.2 }}
				d="M41.800000,1.300000L24,1.300000C23.200000,1.300000,22.700000,1.800000,22.700000,2.600000L22.700000,75.300000L22.600000,75.300000L20.300000,75.300000L20.200000,75.300000L20.200000,2.500000C20.200000,1.700000,19.700000,1.200000,18.900000,1.200000L1.100000,1.200000C0.500000,1.300000,0,1.800000,0,2.500000L0,174C0,174.800000,0.500000,175.300000,1.100000,175.300000L18.900000,175.300000C19.700000,175.300000,20.200000,174.800000,20.200000,174L20.200000,94.200000L20.300000,94.200000L22.600000,94.200000L22.700000,94.200000L22.700000,174C22.700000,174.800000,23.200000,175.300000,24,175.300000L41.800000,175.300000C42.500000,175.300000,42.900000,174.800000,42.900000,174L42.900000,2.500000C42.900000,1.800000,42.400000,1.300000,41.800000,1.300000Z"
				transform="matrix(1 0 0 1 138.05217400000001 0)"
			/>
		</svg>
	);
};
