import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import { PageLink } from "src/components/link";

import cx from "classnames";

import { ReactComponent as HushWordsAndPictures } from "src/images/svg/hush-words-and-pictures.svg";
import { ReactComponent as HushInAQuietRoom } from "src/images/svg/hush-in-a-quiet-room.svg";
import { ReactComponent as HushShopSVG } from "src/images/svg/hush-shop.svg";

import styles from "./shop.module.css";

interface HushShopProps {
	data: {
		copy: string;
	};
}
export const HushShop = ({ data }: HushShopProps) => {
	const hushBook = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "hush-book-1.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<div className={styles.shop} data-custom-cursor="quote">
			<div className={styles.inner}>
				<div className={styles.leftColumn}>
					<div className={styles.svg1Container}>
						<HushWordsAndPictures className={styles.svg1} />
					</div>
					<div className={styles.svg2Container}>
						<HushInAQuietRoom className={styles.svg2} />
					</div>
				</div>
				<div className={styles.rightColumn}>
					<PageLink to="/shop" className={styles.shopLink}>
						<div className={styles.book}>
							<Img
								className={styles.bookImage}
								fluid={hushBook.file.childImageSharp.fluid}
							/>
							<div className={styles.hushShopSVGContainer}>
								<HushShopSVG className={styles.hushShopSVG} />
							</div>
							<span className={cx("kessel", styles.theBooks)}>{data.copy}</span>
						</div>
					</PageLink>
				</div>
			</div>
		</div>
	);
};
