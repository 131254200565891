import React from "react";
import cx from "classnames";
import { HushSVG } from "src/components/animations/hush-svg";
import { HushSpelled } from "src/components/animations/hush-spelled";

import { ReactComponent as HushIs } from "src/images/svg/hush-is.svg";
import { ReactComponent as HushBookClosed } from "src/images/svg/hush-book-closed.svg";
import { ReactComponent as HushBookOpen } from "src/images/svg/hush-book-open.svg";
import { ReactComponent as HushQuote } from "src/images/svg/hush-quote.svg";
import { ReactComponent as HushQuoteCredit } from "src/images/svg/hush-quote-credit.svg";

import styles from "./intro.module.css";

interface HushIntroProps {
	introCopy: string;
}

export const HushIntro = ({ introCopy }: HushIntroProps) => {
	return (
		<div className={styles.intro} data-custom-cursor="star">
			<div className={styles.inner}>
				<HushSVG className={styles.hushSVG} />
				<div className={styles.everythingElse}>
					<div className={styles.hushIs}>
						<div className={styles.hushIsInner}>
							<HushIs />
							<HushIs />
							<HushIs />
							<HushIs />
						</div>
					</div>
					<div className={styles.copyColumn}>
						<div className={styles.copyColumnGraphics}>
							<div className={styles.book}>
								<HushBookClosed className={styles.bookClosed} />
								<HushBookOpen className={styles.bookOpen} />
							</div>
							<HushSpelled className={styles.hushSpelled} />
						</div>
						<div className={styles.quote}>
							<HushQuote className={styles.hushQuote} />
							<HushQuoteCredit className={styles.hushQuoteCredit} />
						</div>
						<p className={cx("times hide-small", styles.copy)}>{introCopy}</p>
					</div>
				</div>
				<p className={cx("times hide-min-medium", styles.copyMobile)}>
					{introCopy}
				</p>
			</div>
		</div>
	);
};
